import * as React from 'react'
import {IconContext} from 'react-icons'
import { FiCoffee } from 'react-icons/fi'
import {FaLinkedin, FaGithub, FaInstagram} from 'react-icons/fa'

const Socials = () => {
    return (
        <IconContext.Provider value={{ className: 'react-icons' }}>
        <div className="social">
          DON'T BE SHY, <a href="mailto:hello@aritro.xyz">SAY HI ↗︎</a>
          <br/>
          <a href="https://linkedin.com/in/aritropaul"><FaLinkedin/></a>
          <a href="https://github.com/aritropaul"><FaGithub/></a>
          <a href="https://instagram.com/not.a.coder"><FaInstagram/></a>
          <a href="https://buymeacoffee.com/aritropaul"><FiCoffee/></a>
        </div>
        </IconContext.Provider>   
    )
}

export default Socials