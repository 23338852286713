import * as React from "react"
import DarkModeToggle from './DarkModeToggle'

const Header = ({selected}) => {

    return (
        <div className="nav row">
        <div className="left six columns">
            <a className="links home" href="/">Aritro Paul</a>
        </div>
        <div className="right six columns">
            <a className="links" href="https://notaritro.xyz">not work</a>
            {
                (selected === "about")? <a className="links about" href="/about">about</a> : <a className="links" href="/about">about</a>
            }
            <DarkModeToggle></DarkModeToggle>
        </div>
        </div>
    )
}

export default Header